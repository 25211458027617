<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-1"
            inline
          >

            <b-form-select
              class="mr-2"
              v-model="filter.searchType"
              :options="searchOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Search Type</b-form-select-option>
              </template>
            </b-form-select>

            <b-form-input
              class="mr-2"
              v-model="filter.searchValue"
              placeholder="Search Input"
            ></b-form-input>

            <!-- <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.category"
              :options="categoryOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Category</b-form-select-option>
              </template>
            </b-form-select> -->

            <!-- <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="filter.ytcategory" :options="ytcategoryOptions">
              <template #first>
                <b-form-select-option :value="null">YouTube Category</b-form-select-option>
              </template>
            </b-form-select> -->

            <!-- <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.language"
              :options="languageOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >language</b-form-select-option>
              </template>
            </b-form-select> -->

            <b-form-group
              class="mr-2"
              style="width: 220px; height: auto;"
            >
              <multiselect
                style="width: 220px; height: auto;"
                v-model="selectedCategories"
                :options="categoryOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Category"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Categories</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedCategories.length && !isOpen"
                  >{{ selectedCategories.length }} Categories</span>
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              class="mr-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedLanguages"
                :options="languageOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Language"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Language</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedLanguages.length && !isOpen"
                  >{{ selectedLanguages.length }} Languages</span>
                </template>
              </multiselect>
            </b-form-group>

            <b-form-group
              class="mr-2 mt-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedGender"
                :options="genderOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="Gender"
                selectLabel=""
                deselectLabel=""
              >
                <span slot="noResult">Gender</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedGender.length && !isOpen"
                  >{{ selectedGender.length }} Genders</span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              class="mr-2 mt-2"
              style="width: 180px; height: auto;"
            >
              <multiselect
                style="width: 180px; height: auto;"
                v-model="selectedCity"
                :options="city"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="City"
                selectLabel=""
                deselectLabel=""
                label="city"
                track-by="city"
              >
                <span slot="noResult">City</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedCity.length && !isOpen"
                  >{{ selectedCity.length }} City</span>
                </template>
              </multiselect>
            </b-form-group>

            <!-- <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.gender"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Gender</b-form-select-option>
              </template>
              <b-form-select-option value="Male">Male</b-form-select-option>
              <b-form-select-option value="Female">Female</b-form-select-option>
              <b-form-select-option value="Animation">Animation</b-form-select-option>
              <b-form-select-option value="Group">Group</b-form-select-option>
            </b-form-select> -->

            <b-form-group
              class="mr-2 mt-2"
              style="width: 200px; height: auto;"
            >
              <multiselect
                style="width: 200px; height: auto;"
                v-model="selectedYtCat"
                :options="ytcategoryOptions"
                multiple
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                placeholder="YouTube Category"
                selectLabel=""
                label="text"
                track-by="value"
                deselectLabel=""
              >
                <span slot="noResult">Youtube Category</span>
                <!-- <template slot="tag">{{ '' }}</template> -->
                <template
                  slot="selection"
                  slot-scope="{ values, search, isOpen }"
                >
                  <span
                    class="multiselect__single"
                    v-if="selectedYtCat.length && !isOpen"
                  >{{ selectedYtCat.length }} Youtube Category</span>
                </template>
              </multiselect>
            </b-form-group>
            <b-input-group
              prepend="Subscibers"
              class="mr-2 mt-2"
            >
              <b-form-input
                v-model="filter.minSubscibers"
                placeholder="Min"
                style="width: 100px;"
              ></b-form-input>
              <b-form-input
                v-model="filter.maxSubscibers"
                placeholder="Max"
                style="width: 100px;"
              ></b-form-input>
            </b-input-group>
            <b-form-select
              class="mr-2 mt-2"
              v-model="filter.ytType"
              :options="ytOptions"
              value-field="value"
              text-field="label"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >YouTube Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-input-group
              prepend="Avg View"
              class="mr-2 mt-2"
            >
              <b-form-input
                v-model="filter.minAvgView"
                placeholder="Min"
                style="width: 100px;"
              ></b-form-input>
              <b-form-input
                v-model="filter.maxAvgView"
                placeholder="Max"
                style="width: 100px;"
              ></b-form-input>
            </b-input-group>

            <b-input-group
              prepend="Price"
              class="mr-2 mt-2"
            >
              <b-form-input
                v-model="filter.minPrice"
                placeholder="Min"
                style="width: 100px;"
              ></b-form-input>
              <b-form-input
                v-model="filter.maxPrice"
                placeholder="Max"
                style="width: 100px;"
              ></b-form-input>
            </b-input-group>

            <b-button
              pill
              class="mt-2 mr-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-2 mr-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
          </b-form>
        </div>

        <b-overlay
          :show="exportLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          @hidden="!exportLoading"
        >
          <b-dropdown
            class="ml-auto"
            variant="outline-white"
            right
            no-caret
          >
            <template #button-content>
              <b-icon icon="list"></b-icon>
            </template>
            <b-dropdown-item @click="exportdata()">
              <b-icon icon="download"></b-icon>
              Export
            </b-dropdown-item>
            <b-dropdown-item @click="addchannelModal">
              <b-icon icon="person-plus"></b-icon>
              Add Channel
            </b-dropdown-item>
          </b-dropdown>
        </b-overlay>
      </div>

      <hr />

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!items.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <b-table
        :items="items"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(basic)="data">
          <a
            :href="data.value.channel_link"
            target="_blank"
            class="meta d-flex justify-content-start align-items-center"
          >
            <img
              :src="data.value.avatar"
              v-if="data.value.avatar"
            />
            <img
              :src="`https://ui-avatars.com/api/?background=F54153&color=fff&name=${data.value.channel_name}`"
              v-else
            />
            <p>
              <strong>{{ data.value.channel_name }}</strong> <br>
              <b-badge
                pill
                variant="info"
              >{{ data.value.category }}</b-badge>
            </p>
          </a>
        </template>
        <template #cell(language)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(gender)="data">
          {{ data.value || 'NA' }}
        </template>
        <template #cell(subscribers)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(yt_category)="data">
          {{ data.value | ytcategory}}
        </template>
        <template #cell(total_view)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(latest_avg_view)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(eng_rate)="data">
          {{ data.value ? `${data.value}%` : 'NA' }}
        </template>
        <template #cell(contact)="data">
          <p v-if="data.value.contact_1 && data.value.contact_1 !== ''">
            {{ data.value.contact_1 }}
          </p>
          <p v-else-if="data.value.contact_2 && data.value.contact_2 !== ''">
            {{ data.value.contact_2 }}
          </p>
          <p v-else>NA</p>
        </template>
        <template #cell(email)="data">
          <div
            v-if="data.value.email_1 && data.value.email_1 !== ''"
            style="max-width: 120px;"
          >
            {{ data.value.email_1 }}
          </div>
          <div
            v-else-if="data.value.email_2 && data.value.email_2 !== ''"
            style="max-width: 120px;"
          >
            {{ data.value.email_2 }}
          </div>
          <p v-else>NA</p>
        </template>
        <template #cell(add_time)="data">
          {{ data.value | dateFormat}}
        </template>
        <template #cell(video_last_pub_date)="data">
          <p v-if="data.value">{{data.value | dateFormat}}</p>
          <p v-else>NA</p>
        </template>
        <template #cell(price)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item
              target="_blank"
              @click="viewinfDetail(data.item)"
            >
              <b-icon icon="eye"></b-icon>
              View
            </b-dropdown-item>
            <b-dropdown-item @click="editchannelModal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteChannel(data.item.channel_id)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <b-pagination
        v-if="total > 50 && !loading"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        align="center"
        pills
        @input="handlePageClick"
      ></b-pagination>
      <!-- AddChannel Modal  -->
      <b-modal
        title="Add Channel"
        ref="addChannelModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>
            <b-form-group label="Resource">
              <validation-provider
                #default="{ errors }"
                name="Resource"
                rules="required"
              >
                <b-form-radio-group
                  v-model="addChannelData.resource"
                  :options="infListOptions"
                  value-field="value"
                  text-field="label"
                  @change="resetuuid"
                  disabled-field="notEnabled"
                ></b-form-radio-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Mediator"
              v-if="addChannelData.resource === 1"
            >
              <validation-provider
                #default="{ errors }"
                name="Mediator"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="uuid"
                  text-field="name"
                  v-model="addChannelData.uuid"
                  :options="mediatorList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Mediator</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agency"
              v-if="addChannelData.resource === 2"
            >
              <validation-provider
                #default="{ errors }"
                name="Agency"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="uuid"
                  text-field="name"
                  v-model="addChannelData.uuid"
                  :options="agencyList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Agency</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="ChannelLink">
              <validation-provider
                #default="{ errors }"
                name="ChannelLink"
                :rules="{ required: true, regex: /^https:\/\/(www.youtube.com\/channel\/)([^ /]+)/ }"
              >
                <b-form-input
                  v-model="addChannelData.channel_link"
                  placeholder="https://www.youtube.com/channel/channelid"
                  :state="errors.length > 0 ? false : null"
                >ChannelLink</b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.gender && addChannelData.gender.length ? addChannelData.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addChannelData.language"
                  :options="languageOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Language</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="categoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.category && addChannelData.category.length ? addChannelData.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="addChannelData.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addChannelData.language && addChannelData.language.length ? addChannelData.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="categoryOptions"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select category</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Contact 1">
              <validation-provider
                #default="{ errors }"
                name="Contact 1"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.contact_1"
                  placeholder="Contact Number 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Contact 2">
              <validation-provider
                #default="{ errors }"
                name="Contact 2"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.contact_2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Contact Number 2"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 1">
              <validation-provider
                #default="{ errors }"
                name="Email address 1"
                rules="email"
              >
                <b-form-input
                  v-model="addChannelData.email_1"
                  placeholder="Email address 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 2">
              <validation-provider
                #default="{ errors }"
                name="Email address 2"
                rules="email"
              >
                <b-form-input
                  v-model="addChannelData.email_2"
                  type="email"
                  placeholder="Email address 2"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Price">
              <validation-provider
                #default="{ errors }"
                name="Price"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="addChannelData.price"
                  placeholder="Influencers latest price"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="danger"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetChannel"
              >Reset</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="addChannel"
              >Add</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- EditChannel Modal  -->
      <b-modal
        title="Edit Channel"
        ref="editChannelModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <!-- <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-select
                  v-model="editChannelData.gender"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Gender</b-form-select-option>
                  </template>
                  <b-form-select-option value="Male">Male</b-form-select-option>
                  <b-form-select-option value="Female">Female</b-form-select-option>
                  <b-form-select-option value="Animation">Animation</b-form-select-option>
                  <b-form-select-option value="Group">Group</b-form-select-option>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.gender && editChannelData.gender.length ? editChannelData.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.category"
                  :state="errors.length > 0 ? false : null"
                  :options="categoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.category && editChannelData.category.length ? editChannelData.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="editChannelData.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editChannelData.language && editChannelData.language.length ? editChannelData.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Contact 1">
              <b-form-input
                v-model="editChannelData.contact_1"
                disabled
                placeholder="Contact Number 1"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Contact 2">
              <validation-provider
                #default="{ errors }"
                name="Contact 2"
                :rules="{ regex: /^[0-9]{10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.contact_2"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Contact Number 2"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 1">
              <validation-provider
                #default="{ errors }"
                name="Email address 1"
                rules="email"
              >
                <b-form-input
                  v-model="editChannelData.email_1"
                  placeholder="Email address 1"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Email address 2">
              <validation-provider
                #default="{ errors }"
                name="Email address 2"
                rules="email"
              >
                <b-form-input
                  v-model="editChannelData.email_2"
                  type="email"
                  placeholder="Email address 2"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Price">
              <validation-provider
                #default="{ errors }"
                name="Price"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="editChannelData.price"
                  placeholder="Influencers latest price"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateChannel"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- Channel View Modal  -->
      <b-modal
        :title="channelName"
        ref="viewModal"
        hide-footer
        :no-close-on-backdrop="true"
        size="lg"
      >
        <!-- centered -->
        <div
          style="text-align:center"
          class="mb-2"
        > <a
            v-bind:href="channelLink"
            target="_blank"
            class="nav-link"
            style="color:blue;"
          > {{channelLink}}
          </a></div>
        <div
          class="d-block text-center"
          v-if="!infDetaildata.length && !loading2"
        >
          <img
            src="@/assets/images/no-data.png"
            width="100"
          />
          <h5 class="mt-2">No Record Found</h5>
        </div>
        <b-table
          :items="infDetaildata"
          :fields="inflfields"
          :busy="loading2"
          head-variant="light"
          responsive
          hover
          v-else
        >
          <template #table-busy>
            <div class="text-center text-primary mt-5 mb-1">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(inf_type)="data">
            {{ data.value | infDetail }}
          </template>
          <template #cell(multimobile)="data">
            {{  data.value.mobile ? ((data.value.mobile ? data.value.mobile : '')+ (data.value.contact_1 && data.value.contact_1 !== data.value.mobile ? (data.value.mobile ? '/' : '') + data.value.contact_1 : '')) : ((data.value.contact_1 ? data.value.contact_1 : '')+ (data.value.contact_2 && data.value.contact_2 !== data.value.contact_1 ? (data.value.contact_1 ? '/' : '') + data.value.contact_2 : '')) }}
          </template>
          <template #cell(multiemail)="data">
            {{ data.value.email ? ((data.value.email ? data.value.email : '') + (data.value.email_1 &&  data.value.email_1 !== data.value.email ? (data.value.email ? '/' : '') + data.value.email_1 : '')) : ((data.value.email_1 ? data.value.email_1 : '') + (data.value.email_2 && data.value.email_2 !== data.value.email_1 ? (data.value.email_1 ? '/' : '') + data.value.email_2 : '')) }}
          </template>
        </b-table>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import constantData from '@/helpers/constant'
import { getList, deleteYoutube, updateYoutube, influDetail, mediatorList, agencyList, addYoutube } from '@/api/youtube'
export default {
  data () {
    return {
      mediatorList: [],
      agencyList: [],
      perPage: 50,
      currentPage: 1,
      editChannelModal: false,
      addChannelModal: false,
      viewModal: false,
      tabledata: [],
      infDetaildata: [],
      loading: true,
      loading2: true,
      exportLoading: false,
      total: 0,
      channelName: null,
      channelLink: null,
      selectedCategories: [],
      selectedLanguages: [],
      selectedGender: [],
      selectedCity: [],
      selectedYtCat: [],
      ytOptions: [{
        value: 0,
        label: 'Admin'
      }, {
        value: 1,
        label: 'Signup'
      }],
      filter: {
        searchType: null,
        ytType: null,
        category: [],
        searchValue: null,
        ytcategory: null,
        language: [],
        gender: [],
        p: 1,
        size: 50,
        city: null
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'basic', label: 'Channel' },
        'language',
        'gender',
        { key: 'subscribers', label: 'Subscribers' },
        { key: 'yt_category', label: 'YouTube Category' },
        { key: 'total_video', label: 'Total Posts' },
        { key: 'total_view', label: 'Total Views' },
        { key: 'latest_avg_view', label: 'Avg Views' },
        { key: 'eng_rate', label: 'Eng. Rate' },
        { key: 'contact', label: 'Contact' },
        { key: 'email', label: 'Email' },
        { key: 'city', label: 'City' },
        'price',
        { key: 'video_last_pub_date', label: 'Last Publish Date' },
        { key: 'add_time', label: 'Add Time' },
        'action'
      ],
      inflfields: [
        { key: 'index', label: 'Sr.' },
        { key: 'inf_type', label: 'Type' },
        { key: 'name', label: 'Name' },
        { key: 'multiemail', label: 'Email' },
        { key: 'multimobile', label: 'Mobile' }
      ],
      items: [],
      editChannelData: {
      },
      addChannelData: {
        category: null,
        language: null,
        gender: null,
        contact_1: null,
        contact_2: null,
        email_1: null,
        email_2: null,
        price: null,
        channel_link: null,
        resource: null,
        uuid: null,
        email: null
      },
      searchOptions: [
        { value: 'channel_name', text: 'Channel Name' },
        { value: 'channel_url', text: 'Channel URL' },
        { value: 'contact', text: 'Contact' },
        { value: 'email', text: 'Email' }
      ],
      ytcategoryOptions: constantData.ytCategory.map(item => {
        return { value: item.value, text: item.label }
      }),
      categoryOptions: constantData.category.map(item => {
        // return { value: item, text: item }
        return item
      }),
      languageOptions: constantData.language.map(item => {
        // return { value: item, text: item }
        return item
      }),
      genderOptions: constantData.genderList,
      infListOptions: constantData.infListAdmin,
      city: [],
      state: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    exportdata () {
      this.exportLoading = true
      // On Click Excel download button
      // export json to Worksheet of Excel
      // only array possible
      var youtuberWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, youtuberWS, 'youtuberData') // sheetAName is name of Worksheet

      // export Excel file
      setTimeout(() => {
        XLSX.writeFile(wb, 'youtube.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        searchType: null,
        category: [],
        language: [],
        searchValue: null,
        gender: [],
        ytType: null,
        ytcategory: null,
        p: 1,
        size: 50
      }
      this.selectedCategories = []
      this.selectedLanguages = []
      this.selectedGender = []
      this.selectedYtCat = []
      this.selectedCity = []
      this.currentPage = 1
      this.getData()
    },
    resetuuid () {
      this.addChannelData.uuid = null
    },
    addchannelModal () {
      this.$refs.addChannelModal.show()
      this.mediatData()
      this.agencyData()
    },
    editchannelModal (channel) {
      this.editChannelData = {
        category: channel.basic.category ? channel.basic.category.split(',') : null,
        language: channel.language ? channel.language.split(',') : null,
        gender: channel.gender ? channel.gender.split(',') : null,
        contact_1: channel.contact.contact_1,
        contact_2: channel.contact.contact_2,
        email_1: channel.email.email_1,
        email_2: channel.email.email_2,
        price: channel.price,
        channel_id: channel.channel_id
      }
      this.$refs.editChannelModal.show()
    },
    cancelAdd () {
      this.resetChannel()
      this.$refs.addChannelModal.hide()
    },
    cancelEdit () {
      this.$refs.editChannelModal.hide()
    },
    resetChannel () {
      this.addChannelData = {
        category: null,
        language: null,
        gender: null,
        contact_1: null,
        contact_2: null,
        email_1: null,
        email_2: null,
        price: null,
        channel_link: null,
        resource: null,
        uuid: null
      }
    },
    async viewinfDetail (item) {
      this.channelName = item.basic.channel_name
      this.channelLink = item.basic.channel_link
      const data = {
        channel_id: item.channel_id
      }
      this.$refs.viewModal.show()
      this.loading2 = true
      const response = await influDetail(data)
      if (response.succ) {
        this.infDetaildata = response.data.map(item => {
          return {
            inf_type: item.inf_type,
            name: item.name,
            multimobile: {
              mobile: item.mobile,
              contact_1: item.contact_1,
              contact_2: item.contact_2
            },
            multiemail: {
              email: item.email,
              email_1: item.email_1,
              email_2: item.email_2
            }
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading2 = false
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      if (this.filter.searchValue && !this.filter.searchType) {
        return this.$bvToast.toast('Please select search type', {
          title: 'Search error!',
          variant: 'danger',
          solid: true
        })
      }
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    async getData () {
      this.loading = true
      this.filter.category = this.selectedCategories.length ? this.selectedCategories.join() : null
      this.filter.language = this.selectedLanguages.length ? this.selectedLanguages.join() : null
      this.filter.gender = this.selectedGender.length ? this.selectedGender.join() : null
      this.filter.city = this.selectedCity.length ? (this.selectedCity.map(a => a.city)).join() : null
      this.filter.ytcategory = this.selectedYtCat.length ? (this.selectedYtCat.map(a => a.value)).join() : null
      const response = await getList(this.filter)
      if (response.succ) {
        this.tabledata = response.data
        this.total = response.totaluser
        this.city = response.city
        this.state = response.state
        this.items = response.data.map(item => {
          return {
            basic: {
              avatar: item.avatar,
              channel_name: item.channel_name,
              category: item.category,
              channel_link: item.channel_link
            },
            subscribers: item.subscribers,
            yt_category: item.yt_category,
            gender: item.gender,
            contact: {
              contact_1: item.contact_1,
              contact_2: item.contact_2
            },
            email: {
              email_1: item.email_1,
              email_2: item.email_2
            },
            language: item.language,
            price: item.price,
            eng_rate: item.eng_rate,
            latest_avg_view: item.latest_avg_view,
            status: item.status,
            total_view: item.total_view,
            total_video: item.total_video,
            last_action_by: item.last_action_by,
            id: item.id,
            video_last_pub_date: item.video_last_pub_date,
            add_time: item.add_time,
            channel_id: item.channel_id,
            city: item.city,
            state: item.state
          }
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async agencyData () {
      const response = await agencyList()
      if (response.succ) {
        this.agencyList = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async mediatData () {
      const response = await mediatorList()
      if (response.succ) {
        this.mediatorList = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    async deleteChannel (channelId) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this youtuber?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            channel_id: channelId,
            status: 1
          }
          if (value) {
            const response = await deleteYoutube(data)
            if (response.succ) {
              this.$bvToast.toast('YouTuber deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.items.splice(this.items.findIndex(a => a.channel_id === channelId), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch(() => {
          console.log('cancel delete.')
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateChannel () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this youtuber?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                channel_id: this.editChannelData.channel_id,
                status: 2,
                gender: this.editChannelData.gender ? this.editChannelData.gender.join() : null,
                category: this.editChannelData.category ? this.editChannelData.category.join() : null,
                language: this.editChannelData.language ? this.editChannelData.language.join() : null,
                contact_1: this.editChannelData.contact_1,
                contact_2: this.editChannelData.contact_2,
                email_1: this.editChannelData.email_1,
                email_2: this.editChannelData.email_2,
                price: this.editChannelData.price
              }
              if (value) {
                const response = await updateYoutube(data)
                if (response.succ) {
                  this.$bvToast.toast('YouTuber updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editChannelModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel update.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addChannel () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this youtuber?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const id = this.addChannelData.channel_link.replace('https://www.youtube.com/channel/', '')
              const channelId = id.replace(/[^A-Za-z0-9_-].*$/g, '') // remove other than A-Za-z0-9_-
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                email: window.localStorage.getItem('user-email'),
                status: 3,
                channel_link: 'https://www.youtube.com/channel/' + channelId,
                channel_id: channelId,
                gender: this.addChannelData.gender ? this.addChannelData.gender.join() : null,
                category: this.addChannelData.category ? this.addChannelData.category.join() : null,
                language: this.addChannelData.language ? this.addChannelData.language.join() : null,
                contact_1: this.addChannelData.contact_1,
                resource: this.addChannelData.resource,
                uuid: this.addChannelData.resource === 3 ? window.localStorage.getItem('user-id') : this.addChannelData.uuid,
                contact_2: this.addChannelData.contact_2,
                email_1: this.addChannelData.email_1,
                email_2: this.addChannelData.email_2,
                price: this.addChannelData.price
              }
              if (value) {
                const response = await addYoutube(data)
                if (response.succ) {
                  this.$bvToast.toast('YouTuber added successfully', {
                    title: 'Added Channel',
                    variant: 'success',
                    autoHideDelay: 2000,
                    solid: true
                  })
                  this.getData()
                  this.resetChannel()
                  this.$refs.addChannelModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add channel error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel add.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style scoped>
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
</style>
