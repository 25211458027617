import request from './index'

export const getList = (data) => {
  return request({
    method: 'GET',
    url: '/youtube/list',
    params: data
  })
}
export const youtubeList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/youtubeList',
    params: data
  })
}
export const mediatorList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/mediator',
    params: data
  })
}
export const agencyList = (data) => {
  return request({
    method: 'GET',
    url: '/influencer/agency',
    params: data
  })
}

export const deleteYoutube = (data) => {
  return request({
    method: 'PUT',
    url: '/youtube/delete',
    data: data
  })
}

export const addYoutube = (data) => {
  return request({
    method: 'POST',
    url: '/youtube/add',
    data: data
  })
}

export const influDetail = (data) => {
  return request({
    method: 'POST',
    url: '/youtube/infDetail',
    data: data
  })
}

export const updateYoutube = (data) => {
  return request({
    method: 'PUT',
    url: '/youtube/update',
    data: data
  })
}
